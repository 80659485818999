<template>
	<div class="mstp-map">
		<sidebar />
		<div class="right">
			<header-top />
			<div class="tile-title">
				<span>电费管理</span><span><i class="el-icon-more"></i></span>
			</div>
			<div class="bar">
				<div class="histogram1" id="histogram1"></div>
				<div class="city">
					<div class="label">城市:</div>
					<select class="select">
						<option>西宁</option>
						<option>海东</option>
						<option>海西</option>
						<option>海南</option>
						<option>海北</option>
						<option>玉树</option>
						<option>黄南</option>
						<option>果洛</option>
					</select>
				</div>
				<div class="type">
					<div class="label">类型</div>
					<select class="select">
						<option>基站</option>
						<option>机房</option>
						<option>营业厅</option>
					</select>
				</div>
				<div class="object">
					<div class="label">对象:</div>
					<select class="select">
						<option>基站A</option>
						<option>基站B</option>
						<option>基站C</option>
					</select>
				</div>
				<div class="time-line">
					<div class="label">时间线</div>
					<input id="startDate" class="form-control" />
					<input id="endDate" class="form-control" />
				</div>
			</div>
			<table class="table">
				<ul>
					<li>类型(万kwh)</li>
					<li>2016-02-25</li>
					<li>2016-02-26</li>
					<li>2016-02-27</li>
					<li>2016-02-28</li>
					<li>2016-02-29</li>
					<li>2016-03-01</li>
					<li>2016-03-02</li>
					<li>2016-03-03</li>
				</ul>
				<tbody id="tb-dianfei"></tbody>
			</table>
			<div class="tile-title"><span>电费录入</span><span></span></div>
			<form role="form">
				<div class="flex">
					<input type="text" class="form-input" placeholder="局站名称" />
					<input type="text" class="form-input" placeholder="月份选择" />
					<input type="text" class="form-input" placeholder="电费单价(元)" />
				</div>
				<div class="flex">
					<input type="text" class="form-input" placeholder="上次抄表日期" />
					<input type="text" class="form-input" placeholder="本次抄表日期" />
					<input type="text" class="form-input" placeholder="线损" />
				</div>
				<div class="flex">
					<input type="text" class="form-input" placeholder="上次抄表日期" />
					<div class="nullBox"></div>
					<div class="nullBox"></div>
				</div>
				<div class="block-title">录入电费合同/局站下的电表表码</div>
				<div class="tab">
					<ul>
						<li>表名</li>
						<li>倍率</li>
						<li>上个月表码</li>
						<li>本月表码</li>
					</ul>
				</div>
				<div class="flex">
					<input type="text" class="form-input" placeholder="电表1" />
					<input type="text" class="form-input" placeholder="1" />
					<input type="text" class="form-input" placeholder="0" />
					<input type="text" class="form-input" placeholder="0" />
				</div>
				<div class="flex" style="margin-top:20px">
					<input type="text" class="form-input" placeholder="电表2" />
					<input type="text" class="form-input" placeholder="1" />
					<input type="text" class="form-input" placeholder="0" />
					<input type="text" class="form-input" placeholder="0" />
				</div>
				<div class="flex" style="margin-top:40px">
					<input type="text" class="form-input" placeholder="本月电量" />
					<input type="text" class="form-input" placeholder="本月电费" />
					<input type="text" class="form-input" placeholder="智能电表读数" />
				</div>
				<div class="flex">
					<input type="text" class="form-input" placeholder="支付方式" />
					<input type="text" class="form-input" placeholder="票据类型" />
					<input type="text" class="form-input" placeholder="其他费用" />
				</div>
				<div class="flex">
					<input type="text" class="form-input" placeholder="备注" />
				</div>
				<div class="flex">
					<textarea
						class="form-input"
						style="height:70px;resize:none"
						placeholder=" 操作提示：
            1.添加的时候，本月电费本月电量留空或输入0，则系统来计算；若手工输入，则以手工输入为准
            2. 人工输入的时候，一定要输入电费和电量两个值"
						disabled
					>
					</textarea>
				</div>
				<div class="btn">
					<span>录入</span>
					<span>取消</span>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import sidebar from './components/sidebar.vue'
import headerTop from './components/header.vue'
import * as echarts from 'echarts'
export default {
	components: {
		sidebar,
		headerTop,
	},
	methods: {
		histogram1() {
			let myChart = echarts.init(document.getElementById('histogram1'))
			const colors = [
				'rgb(255, 102, 0)',
				'rgb(4, 210, 21)',
				'rgb(13, 142, 207)',
				'rgb(252, 210, 2)',
			]
			let option = {
				color: colors,
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					top: '20%',
					left: '3%',
					right: '4%',
					bottom: '3%',
					show: true, // 显示边框
					borderColor: '#fff', // 边框颜色
					containLabel: true, // 包含刻度文字在内
				},
				xAxis: [
					{
						type: 'category',
						axisTick: {
							alignWithLabel: true,
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#4c9bfd',
							},
						},
						data: [
							'Jan',
							'Feb',
							'Mar',
							'Apr',
							'May',
							'Jun',
							'Jul',
							'Aug',
							'Sep',
							'Oct',
							'Nov',
							'Dec',
						],
					},
				],
				yAxis: [
					{
						type: 'value',
						position: 'left',
						axisLine: {
							show: true,
							lineStyle: {
								color: '#4c9bfd',
							},
						},
					},
					{
						type: 'value',
					},
				],
				series: [
					{
						name: '主设备',
						type: 'bar',
						data: [20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20],
					},
					{
						name: '照明',
						type: 'bar',
						data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
					},
					{
						name: '空调',
						type: 'bar',
						data: [20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20],
					},
					{
						name: '总耗能',
						type: 'line',
						data: [30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30],
					},
				],
			}
			option && myChart.setOption(option)
		},
	},
	mounted() {
		this.histogram1()
	},
}
</script>

<style lang="scss" scoped>
.mstp-map {
	*:focus,
	*:active {
		outline: none !important;
	}
	li {
		list-style: none;
	}
	background: url(~@/assets/images/energyManage/background.jpg) 700px 700px;
	display: flex;
	.right {
		flex: 8;
		padding: 0 20px;
		.tile-title {
			font-size: 20px;
			display: flex;
			justify-content: space-between;
			color: #fff;
			background: rgba(0, 0, 0, 0.65);
			padding: 5px 10px;
			margin-top: 15px;
		}
		.form-control {
			border-radius: 0;
			box-shadow: none;
			border-color: #d2d6de;
			background: rgba(0, 0, 0, 0.05);
			border: 1px solid rgba(255, 255, 255, 0.3);
			text-shadow: none;
			color: #fff;
			height: 34px;
			margin-right: 10px;
			border-radius: 4px;
		}
		.form-control:focus {
			border-color: #3c8dbc;
			box-shadow: none;
		}
		.label {
			display: inline-block;
			height: 34px;
			background: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0.3) 0%,
				rgba(0, 0, 0, 1) 100%
			);
			border: 0;
			color: #fff;
			cursor: pointer;
			font-size: 14px;
			padding: 7px;
			margin-right: 15px;
		}
		.label:hover {
			background-color: #e7e7e7;
		}
		.bar {
			background: rgba(0, 0, 0, 0.35);
			position: relative;
			.histogram1 {
				height: 300px;
			}
			.city {
				position: absolute;
				top: 10px;
				left: 100px;
			}
			.type {
				position: absolute;
				top: 10px;
				left: 400px;
			}
			.object {
				position: absolute;
				top: 10px;
				left: 700px;
			}
			.time-line {
				position: absolute;
				top: 10px;
				left: 1000px;
			}
		}
		.table {
			width: 100%;
			margin: 15px 0;
			background: rgba(0, 0, 0, 0.35);
			ul {
				padding: 0;
				display: flex;
				margin: 0;
				li {
					flex: 1;
					font-size: 14px;
					color: #fff;
					height: 35px;
					line-height: 35px;
					padding-left: 10px;
					border-right: 2px solid rgba(0, 0, 0, 0.35);
				}
				li:last-child {
					border-right: none;
				}
			}
		}
		.flex {
			display: flex;
			margin-bottom: 10px;
			.form-input {
				border-radius: 0;
				flex: 1;
				box-shadow: none;
				border-color: #d2d6de;
				background: rgba(0, 0, 0, 0.05);
				border: 1px solid rgba(255, 255, 255, 0.3);
				text-shadow: none;
				color: #fff;
				height: 30px;
				margin-right: 10px;
				border-radius: 4px;
				font-size: 14px;
				-webkit-text-fill-color: rgba(255, 255, 255, 0.8);
			}
			.form-input:focus {
				border-color: #3c8dbc;
				box-shadow: none;
			}
			.nullBox {
				flex: 1;
				margin-right: 16px;
			}
		}
		.block-title {
			font-size: 12px;
			background: rgba(0, 0, 0, 0.65);
			color: #fff;
			padding: 5px 10px 5px;
		}
		.tab {
			color: #fff;
			font-size: 12px;
			margin-bottom: 10px;
			ul {
				margin: 0;
				padding: 0;
				display: flex;
				li {
					flex: 1;
					background: rgba(0, 0, 0, 0.65);
					padding: 5px 8px 6px;
				}
			}
		}
		.btn {
			margin-bottom: 30px;
			span {
				background: linear-gradient(
					to bottom,
					rgba(0, 0, 0, 0.3) 0%,
					rgba(0, 0, 0, 1) 100%
				);
				border: 0;
				color: #fff;
				font-size: 14px;
				display: inline-block;
				padding: 6px 12px;
				cursor: pointer;
				margin-right: 10px;
			}
		}
	}
}
</style>
